import { AsyncPipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ActivationStart, Router, RouterOutlet } from '@angular/router';
import { environment } from '@environments/environment';
import { HeaderComponent } from '@modules/components/header/header/header.component';
import { MenuComponent } from '@modules/components/main-menu/menu/menu.component';
import { ResetFilterService } from '@modules/filter/services/reset-filter/reset-filter.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user/user.service';
import { EXCHANGE_TRANSLATION } from '@services/web-socket/translations/exchange';
import { WebSocketService } from '@services/web-socket/web-socket.service';
import { EXCHANGE_IDENTIFIER, MESSAGE, WSConnection } from '@services/web-socket/ws';
import { USER } from '@tokens/user.token';
import { Observable, filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, MenuComponent, HeaderComponent, AsyncPipe, TranslateModule],
})
export class AppComponent implements OnInit, OnDestroy {
  private userService = inject(UserService);

  private ws = inject(WebSocketService);

  private connection?: WSConnection;

  private translate = inject(TranslateService);

  private resetFilterChanges = inject(ResetFilterService).startWatch();

  route = inject(ActivatedRoute);

  router = inject(Router);

  routeData: Observable<{ fullPage?: boolean }>;

  user = inject(USER);

  constructor() {
    this.routeData = this.router.events.pipe(
      filter((event: any) => event instanceof ActivationStart && event.snapshot.children.length == 0),
      map((event: ActivationStart) => event.snapshot.data),
      takeUntilDestroyed(),
    );

    effect(() => {
      this.translate.setDefaultLang(this.userService.userTranslation());
      this.translate.use(this.userService.userTranslation());
    });
  }

  async ngOnInit() {
    this.connection = await this.ws.connect(environment.wsConnection, EXCHANGE_IDENTIFIER);
    this.connection.addListener(MESSAGE, this.ws.createProcessEventFn(EXCHANGE_TRANSLATION));
    this.resetFilterChanges.unsubscribe();
  }

  @HostListener('window:beforeunload')
  unload() {
    this.connection?.close();
  }

  ngOnDestroy(): void {
    this.connection?.close();
  }
}
