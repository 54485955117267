import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { EnvironmentInjector, Injectable, inject, runInInjectionContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@services/snack-bar/snack-bar.service';
import { Observable, catchError, filter, throwError } from 'rxjs';

function filterHTTPResponse(event: HttpEvent<any>): event is HttpResponse<unknown> {
  return event instanceof HttpResponse;
}

@Injectable()
export class ErrorResponceInterceptor implements HttpInterceptor {
  private snack = inject(SnackBarService);

  private ei = inject(EnvironmentInjector);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      filter(filterHTTPResponse),
      catchError((err: HttpErrorResponse) => {
        if (
          err.status === HttpStatusCode.UnprocessableEntity ||
          err.status === HttpStatusCode.NotFound ||
          err.status === HttpStatusCode.InternalServerError ||
          err.status === HttpStatusCode.Forbidden ||
          err.status === HttpStatusCode.Conflict
        ) {
          this.snack.show('error', { text: this.prepareErrorText(err) });
        } else {
          // Circular dependency fix
          runInInjectionContext(this.ei, () => {
            const UNKNOWN_ERROR_TEXT = inject(TranslateService).instant('unknown_error');
            this.snack.show('error', { text: UNKNOWN_ERROR_TEXT });
          });
        }

        return throwError(() => err);
      }),
    );
  }

  prepareErrorText({ error }: HttpErrorResponse): string {
    const errors = error.custom_message || error.error;

    if (typeof errors === 'string') return errors;
    if (Array.isArray(errors)) return errors.join(', ');

    return '';
  }
}
