import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { CATEGORY } from '@tokens/category.token';
import { filter, merge } from 'rxjs';
import { FilterService } from '../filter/filter.service';

@Injectable({
  providedIn: 'root',
})
export class ResetFilterService {
  private category = inject(CATEGORY).asObservable.pipe(takeUntilDestroyed());

  private routeChange = inject(Router).events.pipe(
    filter((ev) => ev instanceof NavigationEnd),
    takeUntilDestroyed(),
  );

  private filter = inject(FilterService);

  private changes = merge(this.category, this.routeChange).pipe(takeUntilDestroyed());

  startWatch() {
    return this.changes.subscribe(() => this.filter.resetFilterForRequest());
  }
}
